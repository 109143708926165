import React, { Suspense } from 'react'
import { AlertProvider, ModalProvider } from '@/contexts'
import { PersonProvider } from '@/contexts/UserContext'
import { Provider } from 'react-redux'
import Store from '@/store'
import { BrowserRouter } from 'react-router-dom'
import { ScrollToTop } from '@/helpers'
import FullLoader from '@libs/Loaders/FullLoader'
import Router from '@/components/Router'
import Modal from '@hoc/Modal'
import Alert from '@hoc/Alert'
import '@/i18next/i18n'

export default function App() {
  return <>
    <AlertProvider>
      <ModalProvider>
        <PersonProvider>
          <Provider store={Store}>
            <BrowserRouter>
              <ScrollToTop />
              <Suspense fallback={<FullLoader backgroundColor="transparent" color="#202020" />}>
                <Router />
                <Modal />
                <Alert />
              </Suspense>
            </BrowserRouter>
          </Provider>
        </PersonProvider>
      </ModalProvider>
    </AlertProvider>
  </>
}