import {
  SET_ALL_BOUGHT_PACKAGES_HISTORY,
  FETCH_BOUGHT_PACKAGES_HISTORY_DATA,
  SET_BOUGHT_PACKAGES_HISTORY_PAGES_COUNT,
  SET_BOUGHT_PACKAGES_HISTORY_CURRENT_PAGE,
} from '@actions/actionTypes';

const initialState = {
  allPackageHistoryList: [],
  packageHistoryList: {},
  pagesCount: null,
  currentPage: null,
};

export default function boughtPackagesHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_BOUGHT_PACKAGES_HISTORY:
      return {
        ...state,
        allPackageHistoryList: action.allPackageHistoryList,
      };
    case FETCH_BOUGHT_PACKAGES_HISTORY_DATA:
      return {
        ...state,
        packageHistoryList: action.packageHistoryList,
      };
    case SET_BOUGHT_PACKAGES_HISTORY_PAGES_COUNT:
      return {
        ...state,
        pagesCount: action.pagesCount,
      };
    case SET_BOUGHT_PACKAGES_HISTORY_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
}