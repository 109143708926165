import { SET_ADM_USER_PROFILE_DATA } from '@actions/actionTypes'

const initialState = {
  data: null,
}

export default function admUserProfile(state = initialState, action) {
  switch (action.type) {
    case SET_ADM_USER_PROFILE_DATA:
      return {
        ...state,
        data: action.data,
      }
    default:
      return state
  }
}