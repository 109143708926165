import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import Alert from '@libs/Alert/Alert'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LandingRoute, UserRoute, TeacherRoute } from './RouterMiddleware'

const LandingEN = lazy(() => import('../Pages/LandingPages/Main'))
const Personal = lazy(() => import('../Pages/LandingPages/Personal'))
const Group = lazy(() => import('../Pages/LandingPages/Group'))
const Combined = lazy(() => import('../Pages/LandingPages/Combined'))
const Teenagers = lazy(() => import('../Pages/LandingPages/Teenagers'))
const Spanish = lazy(() => import('../Pages/LandingPages/Spanish'))
const Polish = lazy(() => import('../Pages/LandingPages/Polish'))
const French = lazy(() => import('../Pages/LandingPages/French'))
const Italian = lazy(() => import('../Pages/LandingPages/Italian'))
const German = lazy(() => import('../Pages/LandingPages/German'))
const Teachers = lazy(() => import('../Pages/LandingPages/Teachers'))
const About = lazy(() => import('../Pages/LandingPages/About'))
//
const Blog = lazy(() => import('../Pages/LandingPages/Blog'))
const Article = lazy(() => import('../Pages/LandingPages/Article'))
//
const Thx = lazy(() => import('../Pages/LandingPages/Thx'))
const LogInAsUser = lazy(() => import('../Pages/LandingPages/LogIn/LogInAsUser'))
const LogInAsTeacher = lazy(() => import('../Pages/LandingPages/LogIn/LogInAsTeacher'))
const Email = lazy(() => import('../Pages/LandingPages/Email'))
const Intensive = lazy(() => import('../Pages/LandingPages/Intensive'))
const IT = lazy(() => import('../Pages/LandingPages/IT'))
const Marketing = lazy(() => import('../Pages/LandingPages/Marketing'))
const Travel = lazy(() => import('../Pages/LandingPages/Travel'))
const FrequentlyAskedQuestions = lazy(() => import('../Pages/LandingPages/FrequentlyAskedQuestions'))
const PublicOffer = lazy(() => import('../Pages/LandingPages/PublicOffer'))
const Confidentiality = lazy(() => import('../Pages/LandingPages/Confidentiality'))
const WebsiteConditions = lazy(() => import('../Pages/LandingPages/WebsiteConditions'))
//
const Payment = lazy(() => import('../Pages/LandingPages/Payment'))
const Vacancies = lazy(() => import('../Pages/LandingPages/Vacancies'))
const Advertising = lazy(() => import('../Pages/LandingPages/Advertising'))

const RecoveryPassword = lazy(() => import('../Pages/GeneralPages/RecoveryPassword'))

const UserProfile = lazy(() => import('../Pages/UserPages/UserProfile'))
const EditProfile = lazy(() => import('../Pages/GeneralPages/EditProfile/EditProfile'))
const PaymentHistory = lazy(() => import('../Pages/UserPages/PaymentHistory'))
const IntroductoryTest = lazy(() => import('../Pages/GeneralPages/IntroductoryTest/IntroductoryTest'))
const TestResult = lazy(() => import('../Pages/GeneralPages/IntroductoryTest/TestResult'))
const LiveLesson = lazy(() => import('../Pages/GeneralPages/LiveLesson/LiveLesson'))
const Results = lazy(() => import('../Pages/GeneralPages/LiveLesson/Results'))
const Homeworks = lazy(() => import('../Pages/GeneralPages/LiveLesson/Homeworks'))
const UserCourses = lazy(() => import('../Pages/UserPages/UserCourses'))
const LanguagePage = lazy(() => import('../Pages/UserPages/LanguagePage'))
const Purchase = lazy(() => import('../Pages/UserPages/Purchase'))
const PlanPurchase = lazy(() => import('../Pages/UserPages/Purchase/PlanPurchase'))

const TeacherProfile = lazy(() => import('../Pages/TeacherPages/TeacherProfile/TeacherProfile'))
const AddMaterials = lazy(() => import('../Pages/TeacherPages/Materials/AddMaterials'))
const ExerciseConstructor = lazy(() => import('../Pages/TeacherPages/ExerciseConstructor/ExerciseConstructor'))
const TeachersLiveLesson = lazy(() => import('../Pages/GeneralPages/LiveLesson/TeachersLiveLesson'))
const StudentsGroup = lazy(() => import('../Pages/TeacherPages/StudentsGroup'))
const UserCurrentCourse = lazy(() => import('../Pages/TeacherPages/UserCurrentCourse/UserCurrentCourse'))
const PreviousLessons = lazy(() => import('../Pages/TeacherPages/PreviousLessons/PreviousLessons'))
const TeacherProfileCourses = lazy(() => import('../Pages/TeacherPages/TeacherProfileCourses/TeacherProfileCourses'))
const TeacherCourses = lazy(() => import('../Pages/TeacherPages/TeacherCourses/TeacherCourses'))
const LessonContent = lazy(() => import('../Pages/TeacherPages/LessonContent'))
const Materials = lazy(() => import('../Pages/TeacherPages/Materials/Materials'))

const Page404 = lazy(() => import('../Pages/GeneralPages/Page404/Page404'))

export default function Router() {
  const alert = useSelector(state => state.alert)

  return <>
    {
      alert.isShowAlert && <Alert
        type={alert.type}
        message={
          (alert?.message && alert.message.message)
          || (alert?.message && alert.message) || alert.type
        }
      />
    }
    <Switch>
      <LandingRoute path="/" component={LandingEN} exact />
      <LandingRoute path="/personal" component={Personal} />
      <LandingRoute path="/group" component={Group} />
      <LandingRoute path="/combined_course" component={Combined} />
      <LandingRoute path="/english_for_teens" component={Teenagers} />
      <LandingRoute path="/spanish" component={Spanish} />
      <LandingRoute path="/polish" component={Polish} />
      <LandingRoute path="/french" component={French} />
      <LandingRoute path="/italian" component={Italian} />
      <LandingRoute path="/german" component={German} />
      <LandingRoute path="/teachers" component={Teachers} />
      <LandingRoute path="/about" component={About} />
      <LandingRoute path="/articles" component={Blog} exact />
      <LandingRoute path="/articles/:slug" component={Article} />
      <LandingRoute path="/thx" component={Thx} />
      <LandingRoute path="/user/log_in" component={LogInAsUser} />
      <LandingRoute path="/teacher/log_in" component={LogInAsTeacher} />
      <LandingRoute path="/english_for_emails" component={Email} />
      <LandingRoute path="/intensive_english_does_&_did" component={Intensive} />
      <LandingRoute path="/english_for_it" component={IT} />
      <LandingRoute path="/english_for_marketing_and_advertising" component={Marketing} />
      <LandingRoute path="/english_for_travel" component={Travel} />
      <LandingRoute path="/frequently_asked_questions" component={FrequentlyAskedQuestions} />
      <LandingRoute path="/public_offer" component={PublicOffer} />
      <LandingRoute path="/privacy_policy" component={Confidentiality} />
      <LandingRoute path="/website_conditions" component={WebsiteConditions} />
      <LandingRoute path="/payment" component={Payment} />
      <LandingRoute path="/vacancies" component={Vacancies} />
      <LandingRoute path="/advertising" component={Advertising} />

      <LandingRoute path="/users/password/edit" component={RecoveryPassword} />

      <UserRoute path="/profile" component={UserProfile} exact />
      <UserRoute path="/profile/edit" component={EditProfile} />
      <UserRoute path="/profile/payment_history" component={PaymentHistory} />
      <UserRoute path="/profile/exam/introductory_test" component={IntroductoryTest} />
      <UserRoute path="/profile/exam/user/:id/result" component={TestResult} />
      <UserRoute path="/profile/live_lesson/:lessonId/:courseId" component={LiveLesson} />
      <UserRoute path="/profile/lesson/result/:id" component={Results} exact />
      <UserRoute path="/profile/homework/:id/:homework_unit_id" component={Homeworks} />
      <UserRoute path="/profile/lesson/result/:id/:homework_unit_id/:homework" component={Results} />
      <UserRoute path="/profile/courses" component={UserCourses} exact />
      <UserRoute path="/profile/courses/:id" component={LanguagePage} />
      <UserRoute path="/profile/purchase/:id" component={Purchase} exact />
      <UserRoute path="/profile/purchase/:id/plan/:plan_id" component={PlanPurchase} />

      <TeacherRoute path="/teacher_profile" component={TeacherProfile} exact />
      <TeacherRoute path="/teacher_profile/edit" component={EditProfile} />
      <TeacherRoute path="/teacher_profile/user/:id/exam_result" component={TestResult} />
      <TeacherRoute
        path="/teacher_profile/:courseId/lesson/:lessonId/materials"
        component={AddMaterials}
      />
      <TeacherRoute
        path="/teacher_profile/:userId/:liveLessonId/:courseId/previously/exercise_constructor"
        component={ExerciseConstructor}
      />
      <TeacherRoute
        path="/teacher_profile/:userId/:liveLessonId/:courseId/previously/homework_constructor"
        component={ExerciseConstructor}
      />
      <TeacherRoute
        path="/teacher_profile/:userId/:liveLessonId/:courseId/exercise_constructor"
        component={ExerciseConstructor}
      />
      <TeacherRoute
        path="/teacher_profile/user/:userId/live_lesson/:lessonId/:courseId"
        component={TeachersLiveLesson}
      />
      <TeacherRoute
        path="/teacher_profile/:userId/:liveLessonId/:courseId/homework_constructor"
        component={ExerciseConstructor}
      />
      <TeacherRoute path="/teacher_profile/students-group/:id" component={StudentsGroup} />
      <TeacherRoute path="/teacher_profile/user/:id" component={UserCurrentCourse} />
      <TeacherRoute
        path="/teacher_profile/lesson/result/:id/:homework_unit_id/:homework"
        component={Results}
      />
      <TeacherRoute path="/teacher_profile/previous" component={PreviousLessons} />
      <TeacherRoute path="/teacher_profile/lesson/result/:id" component={Results} />
      <TeacherRoute path="/teacher_profile/courses" component={TeacherProfileCourses} exact />
      <TeacherRoute path="/teacher_profile/courses/:id" component={TeacherCourses} />
      <TeacherRoute path="/teacher_profile/content/:id" component={LessonContent} />
      <TeacherRoute path="/teacher_profile/materials" component={Materials} />

      <Route path="/404" component={Page404} />
      <Redirect to="/404" />
    </Switch>
  </>
}