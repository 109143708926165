import React from 'react'
import Header from '@landing_c/General/Header'
import Footer from '@landing_c/General/Footer'
import './classes.scss'

export default function LandingLayout({ children }) {
  return (
    <>
      <Header />
      <main className="landing-content">
        {children}
      </main>
      <Footer />
    </>
  )
}