import {
  SET_ACCOUNT_ROLE,
  SET_ACCOUNT_DATA,
  REMOVE_ACCOUNT,
} from '@actions/actionTypes'

const initialState = {
  role: null,
  data: null,
}

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_ROLE:
      return {
        ...state,
        role: action.role,
      }
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        data: action.data,
      }
    case REMOVE_ACCOUNT:
      return {
        ...state,
        role: null,
        data: null,
      }
    default:
      return state
  }
}