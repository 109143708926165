const arrValues = (data, count) => {
  const { length } = data

  if (length > count) {
    return data.slice(0, count)
  }

  const result = []

  for (let i = 0; i < count; i += 1) {
    if (result.length === count) break
    if (i === length) i = 0
    result.push(data[i])
  }

  return result
}

export { arrValues }