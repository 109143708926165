import React from 'react'
import classes from './button.module.scss'

const Button = ({
  color,
  width,
  source,
  id,
  className,
  type,
  disabled,
  onClick,
  children,
  ...props
}) => {
  const cls = [classes.Button]

  if (color) cls.push(classes[`${color}`])
  if (width) cls.push(classes.w100)

  if (source === 'landing') {
    cls.push(classes.landing)
  } else if (source === 'adm') {
    cls.push(classes.adm)
  }

  return (
    <button
      id={id}
      className={className ? `${cls.join(' ')} ${className}` : `${cls.join(' ')}`}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button