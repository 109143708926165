import { SHOW_ALERT, HIDE_ALERT } from './actionTypes'

let timer

export function hideAlert() {
  if (timer) {
    clearTimeout(timer)
    timer = undefined
  }
  return {
    type: HIDE_ALERT,
  }
}

function timeOutToHide() {
  return dispatch => {
    timer = setTimeout(() => {
      dispatch(hideAlert())
    }, 2500)
  }
}

export function showAlert(data) {
  return {
    type: SHOW_ALERT,
    data,
  }
}

export function newSuccessAlert(message) {
  return dispatch => {
    const success = {
      message,
      type: 'success',
    }
    dispatch(hideAlert())
    dispatch(showAlert(success))
    dispatch(timeOutToHide())
  }
}

export function newErrorAlert(message) {
  return dispatch => {
    const error = {
      message,
      type: 'error',
    }
    dispatch(hideAlert())
    dispatch(showAlert(error))
    dispatch(timeOutToHide())
  }
}