import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import adminpanelEn from '../locales/en/adminpanel.json'
import adminpanelUa from '../locales/uk/adminpanel.json'
import adminpanelRu from '../locales/ru/adminpanel.json'

import buttonsEn from '../locales/en/buttons.json'
import buttonsUa from '../locales/uk/buttons.json'
import buttonsRu from '../locales/ru/buttons.json'

import generalEn from '../locales/en/general.json'
import generalUa from '../locales/uk/general.json'
import generalRu from '../locales/ru/general.json'

import lessonsEn from '../locales/en/lessons.json'
import lessonsUa from '../locales/uk/lessons.json'
import lessonsRu from '../locales/ru/lessons.json'

import titlesEn from '../locales/en/titles.json'
import titlesUa from '../locales/uk/titles.json'
import titlesRu from '../locales/ru/titles.json'

import userEn from '../locales/en/user.json'
import userUa from '../locales/uk/user.json'
import userRu from '../locales/ru/user.json'

import errorEn from '../locales/en/error.json'
import errorUa from '../locales/uk/error.json'
import errorRu from '../locales/ru/error.json'

import landingUa from '../locales/uk/landing.json'
import navigationUa from '../locales/uk/navigation.json'
import landingRu from '../locales/ru/landing.json'
import navigationRu from '../locales/ru/navigation.json'
import landingEn from '../locales/en/landing.json'
import navigationEn from '../locales/en/navigation.json'

import modalsUa from '../locales/uk/modals.json'
import modalsRu from '../locales/ru/modals.json'

const resources = {
  en: {
    buttons: buttonsEn,
    lessons: lessonsEn,
    titles: titlesEn,
    user: userEn,
    general: generalEn,
    error: errorEn,
    adminpanel: adminpanelEn,
    landing: landingEn,
    navigation: navigationEn,
  },
  uk: {
    buttons: buttonsUa,
    lessons: lessonsUa,
    titles: titlesUa,
    user: userUa,
    general: generalUa,
    error: errorUa,
    adminpanel: adminpanelUa,
    landing: landingUa,
    navigation: navigationUa,
    modals: modalsUa,
  },
  ru: {
    buttons: buttonsRu,
    lessons: lessonsRu,
    titles: titlesRu,
    user: userRu,
    general: generalRu,
    error: errorRu,
    adminpanel: adminpanelRu,
    landing: landingRu,
    navigation: navigationRu,
    modals: modalsRu,
  },
}

const currentLang = localStorage.getItem('i18nextLng')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: currentLang || 'uk',
    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false,
    },
    resources,
  })

export default i18n