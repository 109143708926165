import imagesLoaded from 'imagesloaded'

const useFlexibleGrid = (wrapperRef, itemsArrayRef) => {
  const wrapper = wrapperRef.current
  const elements = itemsArrayRef.current

  function resizeGridItem(item) {
    const wStyles = getComputedStyle(wrapper)
    const rowHeight = parseInt(wStyles['grid-auto-rows'], 10)
    const rowGap = parseInt(wStyles['grid-row-gap'], 10)
    const rowSpan = Math.ceil((item.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap))
    item.style.gridRowEnd = `span ${rowSpan}`
  }

  function resizeInstance(instance) {
    resizeGridItem(instance.elements[0]);
  }

  function resizeAllGridItems() {
    for (let i = 0; i < elements.length; i += 1) {
      resizeGridItem(elements[i]);
    }
  }

  resizeAllGridItems()

  for (let i = 0; i < elements.length; i += 1) {
    imagesLoaded(elements[i], resizeInstance);
  }
  window.onresize = resizeAllGridItems
}

export { useFlexibleGrid }