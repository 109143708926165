import { SET_AVAILABLE_BOUGHT_PACKAGES } from '@actions/actionTypes';

const initialState = {
  availablePackageList: [],
  isAvailableBoughtPackagesLoaded: false,
};

export default function availableBoughtPackagesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AVAILABLE_BOUGHT_PACKAGES:
      return {
        ...state,
        availablePackageList: action.availablePackageList,
        isAvailableBoughtPackagesLoaded: true,
      };
    default:
      return state;
  }
}