import { SHOW_APPROVAL_MODAL, HIDE_APPROVAL_MODAL } from './actionTypes'

export function showApprovalModal(actionType, callback) {
  return {
    type: SHOW_APPROVAL_MODAL,
    actionType,
    callback,
  }
}

export function hideApprovalModal() {
  return {
    type: HIDE_APPROVAL_MODAL,
  }
}