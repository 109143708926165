import { combineReducers } from 'redux'

import lesson from './lessonReducer'
import account from './accountReducer'
import teacher from './teacherReducer'
import cardList from './CardListReducer'
import alert from './alertReducer'
import articleModal from './AdmArticlesModalReducer'
import userProfile from './userProfileReducer'
import zoom from './zoomReducer'
import introTest from './IntroductoryTestReducer'
import materialsModal from './materialsModalReducer'
import finishLessonModal from './finishModalReducer'
import asideBar from './asideBarReducer'
import approvalModal from './ApprovalReducer'
import adminWorkspaceUsers from './AdmWorkspaceUsersReducer'
import datePicker from './AdmDatePickerModalReducer'
import exerciseConstructor from './ExerciseConstructorReducer'
import admUserModal from './admUserModalReducer'
import admUserProfile from './AdmUserProfileReducer'
import campModal from './campModal'
import groupLessons from './groupLessonsReducer'
import userComments from './userCommentsReducer'
import reportModal from './reportModalReducer'
import exerciseReports from './exerciseReportsReducer'
import availableBoughtPackages from './availableBoughtPackagesReducer'
import boughtPackagesHistory from './boughtPackagesHistoryReducer'

export default combineReducers({
  lesson,
  account,
  teacher,
  cardList,
  alert,
  articleModal,
  userProfile,
  zoom,
  introTest,
  materialsModal,
  admUserModal,
  finishLessonModal,
  asideBar,
  approvalModal,
  adminWorkspaceUsers,
  datePicker,
  exerciseConstructor,
  admUserProfile,
  campModal,
  groupLessons,
  userComments,
  reportModal,
  exerciseReports,
  availableBoughtPackages,
  boughtPackagesHistory,
})