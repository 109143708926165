import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { PersonContext } from '@/contexts'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import classes from './classes.module.scss'

export default () => {
  const { person } = useContext(PersonContext)
  const { changeSettings } = useModal()
  const { t } = useTranslation()

  let path = ''

  if (person?.user.role === 'user') {
    path = '/profile'
  } else if (person?.user.role === 'teacher') {
    path = '/teacher_profile'
  }
  const onClickHandler = (e) => {
    const { type } = e.target.dataset
    changeSettings(true, { type })
  }

  return (
    <>
      {
        person?.user.role && person?.user.role !== 'admin'
          ? (
            <Link to={path}>
              <img src={person.user.avatar_url} alt="avatar" className={classes.avatar} />
            </Link>
          )
          : (
            <button
              type="button"
              onClick={onClickHandler}
              className={classes.button}
              data-type="log_in_user"
            >
              {t('buttons:log_in')}
            </button>
          )
      }

    </>
  )
}
