import React, { useState } from 'react'
import useModal from '@/hooks/useModal'
import useAlert from '@/hooks/useAlert'
import { useTranslation } from 'react-i18next'
import { userRegistration } from '@services/fetchServices'
import * as yup from 'yup'
import logo from '@images/SVG/logo'
import closeIcon from '@images/SVG/x'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import FormInput from './FormInput'
import PhoneInput from './PhoneInput'
import Checkbox from './Checkbox'
import style from './style/registration_m.module.scss'

export default () => {
  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState(false)
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState()
  const [requiredPhoneLength, setRequiredPhoneLength] = useState()
  const {
    settings,
    changeSettings,
  } = useModal()
  const { changeAlertSettings } = useAlert()
  const { t } = useTranslation()

  const onCloseHandler = () => {
    changeSettings(false)
  }

  const onClickHandler = e => {
    const { type } = e.target.dataset

    changeSettings(true, { type })
  }

  const submitRegistration = (values, actions) => {
    const formData = { ...values }
    const trimmedName = formData.name
      .replace(/^['"`\s-]+/, '')
      .replace(/['"`\s-]+$/, '')
    const nameWithDeletedExtraCharacters = trimmedName
      .replace(/[a-zа-щєіїьюя]['"`\s-]*\s+['"`\s-]*[a-zа-щєіїьюя]/gi, str => `${str[0]} ${str[str.length - 1]}`)
      .replace(/['"`-]+/g, str => str[0])
    const partsOfName = nameWithDeletedExtraCharacters.split(' ')
    const nameForRegistration = partsOfName
      .map(str => str[0].toUpperCase() + str.slice(1).toLowerCase())
      .join(' ')
      .replace(/-[a-zа-щєіїьюя]/gi, str => str.toUpperCase())

    formData.name = nameForRegistration
    formData.email = formData.email.toLowerCase()

    userRegistration(formData)
      .finally(() => actions.setSubmitting(false))
      .then(res => {
        if (res.status === 200) {
          changeAlertSettings(true, {
            type: 'success',
            message: 'Успішно зареєстровано!',
          })
          changeSettings(true, { type: 'confirm_account' })

          window.dataLayer.push({
            event: 'successful_registration',
          })
        }
      })
      .catch(error => {
        changeAlertSettings(true, {
          type: 'error',
          message: 'Помилка реєстрації!',
        })

        if (error.response.status === 500) setIsEmailAlreadyExists(true)
      })
  }

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit)
    setRequiredPhoneLength(requiredLength)
  }

  const schema = yup.object({
    name: yup
      .string()
      .required('Будь ласка, введіть ім\'я')
      .matches(/^[a-zа-щєіїьюя'"`\s-]+$/i, 'Невірне ім\'я')
      .min(2, 'Ім\'я закоротке'),
    email: yup
      .string()
      .required('Будь ласка, введіть email')
      .email('Невірна електронна пошта'),
    phone: yup
      .string()
      .min(minPhoneLengthLimit + 2, 'Будь ласка, введіть номер телефону')
      .length(requiredPhoneLength + 1, 'Невірний номер телефону'),
    acceptedPrivacyPolicy: yup
      .boolean()
      .oneOf([true], 'Ви повинні погодитись!'),
  })

  return (
    <div className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <img src={logo} alt="logo" />
          <span onClick={onCloseHandler}>
            <img src={closeIcon} alt="close" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <div className={style.title}>
          <h1>{t('modals:registration')}</h1>
          <button
            type="button"
            onClick={onClickHandler}
            data-type="log_in_user"
          >
            {t('buttons:log_in')}
          </button>
        </div>
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '+380',
            acceptedPrivacyPolicy: true,
            registration_mark: settings.mark,
          }}
          validationSchema={schema}
          onSubmit={submitRegistration}
        >
          {formik => (
            <Form className={style.inputGroup}>
              <FormInput
                className={style.input}
                name="name"
                type="text"
                placeholder={t('modals:name')}
                maxLength={30}
              />
              <FormInput
                className={style.input}
                name="email"
                type="email"
                placeholder="Email"
                isEmailAlreadyExists={isEmailAlreadyExists}
                setIsEmailAlreadyExists={isEmailAlreadyExists => setIsEmailAlreadyExists(isEmailAlreadyExists)}
              />
              <PhoneInput
                name="phone"
                type="tel"
                setPhoneParameters={(minLengthLimit, requiredLength) => setPhoneParameters(minLengthLimit, requiredLength)}
              />
              <Checkbox
                name="acceptedPrivacyPolicy"
                type="checkbox"
              >
                Я погоджуюсь з
                <Link to="/privacy_policy" target="_blank" className={style.link}> політикою конфіденційності </Link>
                та
                <Link to="/website_conditions" target="_blank" className={style.link}> правилами користування сайтом</Link>
              </Checkbox>
              <button
                className={`${style.input} register-btn`}
                type="submit"
                disabled={!(formik.dirty && formik.isValid && formik.values.acceptedPrivacyPolicy && !formik.isSubmitting)}
              >
                {t('buttons:register')}
              </button>
            </Form>
          )}
        </Formik>
      </main>
    </div>
  )
}