import React from 'react'
import Media from 'react-media'
import SendInfoMobile from './SendInfo.mobile'
import SendInfoDesktop from './SendInfo.desktop'

export default () => {
  return (
    <Media
      queries={{
        small: '(max-width: 576px)',
        large: '(min-width: 577px)',
      }}
    >
      {
        matches => (
          <>
            {
              matches.small && <SendInfoMobile />
            }
            {
              matches.large && <SendInfoDesktop />
            }
          </>
        )
      }
    </Media>
  )
}