import {
  UPDATE_USERS_DATA,
  SET_TASKS,
  CHANGE_USER_LIST,
  SET_USERS_PAGES_COUNT,
  SET_USERS_CURRENT_PAGE,
  UPDATE_GLOBAL_LOADING_STATUS,
  CHANGE_USER_SEARCHWORD,
  UPDATE_LOADING_STATUS,
  UPDATE_LAST_REQUEST,
} from '@actions/actionTypes'

const initialState = {
  data: null,
  tasks: null,
  userList: null,
  pagesCount: null,
  currentPage: null,
  isGlobalLoading: false,
  searchWord: '',
  isLoading: true,
  loadingGroupName: null,
  lastRequest: {
    group: null,
    page: null,
  },
}

export default function AdmWorkspaceUsersReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USERS_DATA:
      return {
        ...state,
        data: action.data,
      }
    case SET_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      }
    case CHANGE_USER_LIST:
      return {
        ...state,
        userList: action.userList,
      }
    case SET_USERS_PAGES_COUNT:
      return {
        ...state,
        pagesCount: action.pagesCount,
      }
    case SET_USERS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case UPDATE_GLOBAL_LOADING_STATUS:
      return {
        ...state,
        isGlobalLoading: action.globalLoadingStatus,
      }
    case CHANGE_USER_SEARCHWORD:
      return {
        ...state,
        searchWord: action.searchWord,
      }
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.loadingStatus,
        loadingGroupName: action.loadingGroupName,
      }
    case UPDATE_LAST_REQUEST:
      return {
        ...state,
        lastRequest: action.lastRequest,
      }
    default:
      return state
  }
}