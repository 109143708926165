import {
  SHOW_FINISH_LESSON_MODAL,
  HIDE_FINISH_LESSON_MODAL,
  SET_DATA_TO_MODAL,
} from '@actions/actionTypes'

const initialState = {
  isShowFinishModal: false,
  lessonId: null,
  userId: null,
  courseId: null,
  trialLessonLanguage: null,
}

export default function finishLessonReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_FINISH_LESSON_MODAL:
      return {
        ...state,
        isShowFinishModal: true,
      }
    case SET_DATA_TO_MODAL:
      return {
        ...state,
        lessonId: action.lessonId,
        userId: action.userId,
        courseId: action.courseId,
        trialLessonLanguage: action.trialLessonLanguage,
      }
    case HIDE_FINISH_LESSON_MODAL:
      return {
        ...state,
        isShowFinishModal: false,
        lessonId: null,
        userId: null,
        courseId: null,
        trialLessonLanguage: null,
      }
    default:
      return state
  }
}