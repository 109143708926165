import React from 'react'
import Media from 'react-media'
import RegistrationMobile from './Registration.mobile'
import RegistrationDesktop from './Registration.desktop'

export default () => {
  return (
    <Media
      queries={{
        small: '(max-width: 576px)',
        large: '(min-width: 577px)',
      }}
    >
      {
        matches => (
          <>
            {
              matches.small && <RegistrationMobile />
            }
            {
              matches.large && <RegistrationDesktop />
            }
          </>
        )
      }
    </Media>
  )
}