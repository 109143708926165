import React, { createContext, useState } from 'react'

export const Context = createContext(false)

const defaultSettings = {
  isVisible: false,
  data: {
    type: null,
    message: null,
  },
}

export const AlertProvider = ({
  children,
  settings,
}) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings)

  const changeAlertSettings = (isVisible, data = null) => {
    setCurrentSettings({ isVisible, ...data })
  };
  return (
    <Context.Provider value={{
      alertSettings: currentSettings,
      changeAlertSettings,
    }}
    >
      {children}
    </Context.Provider>
  )
}