import React from 'react'
import { Formik, Field, Form } from 'formik'
import closeICO from '@images/SVG/x'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import style from './style_d.module.scss'

export default () => {
  const { changeSettings } = useModal()
  const { t } = useTranslation()
  const onCloseHandler = () => {
    changeSettings(false)
  }
  return (
    <div className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <span onClick={onCloseHandler}>
            <img src={closeICO} alt="close" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <h1>{t('modals:set_new_password')}</h1>
        <Formik initialValues={{
          pass: '',
          re_pass: '',
        }}
        >
          <Form className={style.inputGroup}>
            <Field
              className={style.input}
              name="pass"
              type="password"
              placeholder={t('modals:new_password')}
            />
            <Field
              className={style.input}
              name="re-pass"
              type="password"
              placeholder={t('modals:repeat_password')}
            />
            <button className={style.input} type="submit">{t('modals:set_password')}</button>
          </Form>
        </Formik>
      </main>
    </div>
  )
}