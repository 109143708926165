import { SHOW_ARTICLES_MODAL, HIDE_ARTICLES_MODAL } from '@actions/actionTypes'

const initialState = {
  isShowModal: false,
  callback: null,
}

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ARTICLES_MODAL:
      return {
        ...state,
        isShowModal: true,
        callback: action.callback,
      }
    case HIDE_ARTICLES_MODAL:
      return {
        ...state,
        isShowModal: false,
        callback: null,
      }
    default:
      return state
  }
}