import {
  SET_STUDENTS_CAMPS,
  SET_SEARCH_STUDENTS_CAMPS_VALUE,
  SET_SELECTED_STUDING_STATUS,
  SET_CAMP_STUDENTS,
  SET_SEARCH_CAMP_STUDENTS_VALUE,
} from '@actions/actionTypes'

const initialState = {
  studentsCamps: null,
  searchStudentsCampsValue: '',
  selectedStudyingStatus: null,
  campStudents: null,
  searchCampStudentsValue: '',
}

export default function campModal(state = initialState, action) {
  switch (action.type) {
    case SET_STUDENTS_CAMPS:
      return {
        ...state,
        studentsCamps: action.studentsCamps,
      }
    case SET_SEARCH_STUDENTS_CAMPS_VALUE:
      return {
        ...state,
        searchStudentsCampsValue: action.searchStudentsCampsValue,
      }
    case SET_SELECTED_STUDING_STATUS:
      return {
        ...state,
        selectedStudyingStatus: action.selectedStudyingStatus,
      }
    case SET_CAMP_STUDENTS:
      return {
        ...state,
        campStudents: action.campStudents,
      }
    case SET_SEARCH_CAMP_STUDENTS_VALUE:
      return {
        ...state,
        searchCampStudentsValue: action.searchCampStudentsValue,
      }
    default:
      return state
  }
}