import React, { useState } from 'react'
import Media from 'react-media'
import { useDispatch } from 'react-redux'
import { toggleAsideBar } from '@actions/asideBarActions'
import Modal from '@libs/Modals/DropDownHeaderMenu/Modal'
import Setting from '@img/asq'
import Avatar from '@img/user'
import MenuBtn from '@img/MenuBtn'
import classes from './HeaderPanel.module.scss'

export default function HeaderPanel({
  userPhoto,
  role,
}) {
  const dispatch = useDispatch()
  const [showSupportMenu, setShowSupportMenu] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  let userAvatar = Avatar
  if (userPhoto) userAvatar = userPhoto

  const toggleNavBar = () => {
    dispatch(toggleAsideBar())
  }

  return (
    <>
      {
        role === 'user' && (
          <div className={classes.element}>
            <span onClick={() => setShowSupportMenu(true)}>
              <img src={Setting} alt="Setting" />
            </span>
            {
              showSupportMenu && (
                <>
                  <div
                    className={classes.modal}
                    onClick={() => setShowSupportMenu(false)}
                  />
                  <Modal type="support" />
                </>
              )
            }
          </div>
        )
      }
      <Media
        queries={{
          small: '(max-width: 576px)',
          large: '(min-width: 577px)',
        }}
      >
        {
          matches => (
            <>
              {
                matches.small && (
                  <div className={classes.element} onClick={toggleNavBar}>
                    <img src={MenuBtn} alt="menu" />
                  </div>
                )
              }
              {
                matches.large && (
                  <div className={classes.avatar}>
                    <span onClick={() => setShowProfileMenu(true)}>
                      <img src={userAvatar} alt="userPhoto" className={classes.user_photo} />
                    </span>
                    {
                      showProfileMenu && (
                        <>
                          <div
                            className={classes.modal}
                            onClick={() => setShowProfileMenu(false)}
                          />
                          <Modal
                            role={role}
                            type="profile"
                            onAction={setShowProfileMenu}
                          />
                        </>
                      )
                    }
                  </div>
                )
              }
            </>
          )
        }
      </Media>
    </>
  )
}