import React from 'react'

const styles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, min-content)',
  gridGap: '50px',
  marginTop: '15px',
}

export default ({
  lang,
  currentLang,
  onChange,
}) => {
  const langHelper = (e) => {
    const { value } = e.target.dataset
    onChange(value)
  }

  const activeStyle = {
    textDecoration: 'underline',
  }

  return (
    <div style={styles}>
      {
        lang.map(l => {
          const spanStyles = currentLang === l.value ? activeStyle : null
          return <span
            key={l.value}
            // style={{ cursor: 'pointer', ...spanStyles }}
            style={spanStyles}
            data-value={l.value}
            onClick={langHelper}
          >
            {l.label}
          </span>
        })
      }
    </div>
  )
}