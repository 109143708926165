import React from 'react'
import Profile from './Profile'
import Support from './Support'
import classes from './Modal.module.scss'

export default function Modal({
  type,
  role,
  onAction,
}) {
  const modalCls = [classes.modal]

  let ModalData

  switch (type) {
    case 'profile':
      modalCls.push(classes.profile)

      ModalData = Profile
      break
    case 'support':
      ModalData = Support
      break
    default:
      break
  }

  return <section className={modalCls.join(' ')}>
    <ModalData role={role} onAction={onAction} />
  </section>
}