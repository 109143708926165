import React from 'react'
import classes from './Modal.module.scss'

export default function Support() {
  const openFAQ = () => {
    window.open('/frequently_asked_questions')
  }

  const openPrivacyPolicy = () => {
    window.open('/privacy_policy')
  }

  const openTermsOfUse = () => {
    window.open('/website_conditions')
  }

  return (
    <>
      <div className={`${classes.link_btn} ${classes.link_border}`}>
        <span onClick={openFAQ}>
          FAQ
        </span>
      </div>
      <div className={`${classes.link_btn} ${classes.link_border}`}>
        <span onClick={openPrivacyPolicy}>
          Privacy Policy
        </span>
      </div>
      <div className={`${classes.link_btn} ${classes.link_border}`}>
        <span onClick={openTermsOfUse}>
          Terms of Use
        </span>
      </div>
      <div className={classes.contact}>
        <h4>Contact Us</h4>
        <a href="tel:+380681205605">+38 068 120 56 05</a>
        <a href="tel:+380955838283">+38 095 583 82 83</a>
        <a href="mailto:yolostudyonline@gmail.com?subject=Support">yolostudyonline@gmail.com</a>
      </div>
    </>
  )
}