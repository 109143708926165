import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Chevron from '@img/chevron'
import logo from './img/logo'
import LanguageChooser from '../LanguageChooser'
import CurrentPerson from '../CurrentPerson'
import classes from './classes.module.scss'

export default function Header() {
  const { t } = useTranslation()
  const [isShowDropdownAdults, setIsShowDropdownAdults] = useState(false)
  const [isShowDropdownLanguages, setIsShowDropdownLanguages] = useState(false)

  const adultsArrowClasses = [classes.chevron]
  const languagesArrowClasses = [classes.chevron]

  if (isShowDropdownAdults) adultsArrowClasses.push(classes.open)
  if (isShowDropdownLanguages) languagesArrowClasses.push(classes.open)

  const showAdults = () => setIsShowDropdownAdults(true)
  const hideAdults = () => setIsShowDropdownAdults(false)

  const showLanguages = () => setIsShowDropdownLanguages(true)
  const hideLanguages = () => setIsShowDropdownLanguages(false)

  return (
    <header className={classes.header}>
      <Link to="/">
        <img
          className={classes.logo}
          src={logo}
          alt="logo"
          width="68"
          height="36"
        />
      </Link>
      <div className={classes.links}>
        <nav>
          <div className={classes.dropdown}>
            <button
              className={classes.dropbtn}
              type="button"
              onMouseEnter={showAdults}
              onMouseLeave={hideAdults}
            >
              <span>{t('navigation:adults')}</span>
              <img
                className={adultsArrowClasses.join(' ')}
                src={Chevron}
                alt="Chevron"
              />
            </button>
            <div
              className={classes.dropdownContent}
              style={isShowDropdownAdults ? { display: 'block' } : null}
              onMouseEnter={showAdults}
              onMouseLeave={hideAdults}
            >
              <Link
                to="/personal"
                onClick={hideAdults}
              >
                {t('navigation:personal')}
              </Link>
              <Link
                to="/group"
                onClick={hideAdults}
              >
                {t('navigation:group')}
              </Link>
              <Link
                to="/combined_course"
                onClick={hideAdults}
              >
                {t('navigation:combined_course')}
              </Link>
            </div>
          </div>
          <Link to="/english_for_teens">{t('navigation:children_and_teenagers')}</Link>
          <div className={classes.dropdown}>
            <button
              className={classes.dropbtn}
              type="button"
              onMouseEnter={showLanguages}
              onMouseLeave={hideLanguages}
            >
              <span>{t('navigation:languages')}</span>
              <img
                className={languagesArrowClasses.join(' ')}
                src={Chevron}
                alt="Chevron"
              />
            </button>
            <div
              className={classes.dropdownContent}
              style={isShowDropdownLanguages ? { display: 'block' } : null}
              onMouseEnter={showLanguages}
              onMouseLeave={hideLanguages}
            >
              <Link
                to="/spanish"
                onClick={hideLanguages}
              >
                {t('navigation:spanish')}
              </Link>
              <Link
                to="/polish"
                onClick={hideLanguages}
              >
                {t('navigation:polish')}
              </Link>
              <Link
                to="/french"
                onClick={hideLanguages}
              >
                {t('navigation:french')}
              </Link>
              <Link
                to="/italian"
                onClick={hideLanguages}
              >
                {t('navigation:italian')}
              </Link>
              <Link
                to="/german"
                onClick={hideLanguages}
              >
                {t('navigation:german')}
              </Link>
            </div>
          </div>
          <Link to="/teachers">{t('navigation:teachers')}</Link>
          <Link to="/about">{t('navigation:school')}</Link>
          <Link to="/articles">{t('navigation:blog')}</Link>
        </nav>
        <a href="tel:+380955838283">+38095 583 82 83</a>
        <LanguageChooser />
        <CurrentPerson />
      </div>
    </header>
  )
}