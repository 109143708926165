import React, { useContext } from 'react'
import { PersonContext } from '@/contexts'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Logo from '@img/yolo_logo_black'
import HeaderPanel from './HeaderPanel/HeaderPanel'
import classes from './headerComponent.module.scss'

const Header = () => {
  const { person } = useContext(PersonContext)
  const { data } = useSelector(state => state.account)

  return (
    <header className={classes.Header}>
      <section className={classes.header_logo}>
        <Link to="/">
          <div className={classes.aside_logo}>
            <img src={Logo} alt="logo" />
          </div>
        </Link>
      </section>
      <nav className={classes.content}>
        <div className={classes.menu_panel}>
          {
            person && <HeaderPanel
              role={person.user.role}
              userPhoto={data?.avatar_url || person.user.avatar_url}
            />
          }
        </div>
      </nav>
    </header>
  )
}

export default React.memo(Header)