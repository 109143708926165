import {
  GET_COURSE_UNITS,
  GET_COURSE_CONTENTS_UNITS,
  SET_ALL_CHECKED,
  LOAD_UNIT_EXERCISES,
  SET_CHECKED_UNIT,
  SET_CHECKED_HOMEWORK,
  REMOVE_CONSTRUCTOR_DATA,
  UPDATE_LESSON_EXERCISES,
  UPDATE_LESSON_HOMEWORKS,
} from '@actions/actionTypes'

const initialState = {
  course: null,
  courseContents: null,
  units: [],
  unitsContents: [],
  checkedUnit: null,
  checkedHomework: null,
  exercises: {},
  addedExercises: [],
  addedHomeworks: [],
  categoryAllChecked: false,
}

export default function ExerciseConstructor(state = initialState, action) {
  switch (action.type) {
    case GET_COURSE_UNITS:
      return {
        ...state,
        course: action.course,
        units: action.units,
      }
    case GET_COURSE_CONTENTS_UNITS:
      return {
        ...state,
        courseContents: action.course,
        unitsContents: action.units,
      }
    case SET_CHECKED_UNIT:
      return {
        ...state,

        checkedUnit: action.checkedUnit,
      }
    case SET_CHECKED_HOMEWORK:
      return {
        ...state,

        checkedHomework: action.checkedHomework,
      }
    case LOAD_UNIT_EXERCISES:
      return {
        ...state,
        exercises: action.exercises,
      }
    case SET_ALL_CHECKED:
      return {
        ...state,
        categoryAllChecked: action.categoryAllChecked,
      }
    case UPDATE_LESSON_EXERCISES:
      return {
        ...state,
        addedExercises: action.addedExercises,
      }
    case UPDATE_LESSON_HOMEWORKS:
      return {
        ...state,
        addedHomeworks: action.addedHomeworks,
      }
    case REMOVE_CONSTRUCTOR_DATA:
      return {
        ...state,
        course: null,
        units: [],
        checkedUnit: null,
        checkedHomework: 1,
        exercises: [],
        addedExercises: [],
        addedHomeworks: [],
      }
    default:
      return state
  }
}