import {
  FETCH_REPORTS_DATA,
  SET_REPORTS_PAGES_COUNT,
  SET_REPORTS_CURRENT_PAGE,
  SET_SELECTED_ACCOUNT_TYPE,
  SET_SELECTED_WORK_TYPE,
  SET_SELECTED_PROCESSING_STATUS,
} from '@actions/actionTypes';

const initialState = {
  reportList: {},
  pagesCount: null,
  currentPage: null,
  selectedAccountType: null,
  selectedWorkType: null,
  selectedProcessingStatus: null,
};

export default function exerciseReportsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTS_DATA:
      return {
        ...state,
        reportList: action.reportList,
      };
    case SET_REPORTS_PAGES_COUNT:
      return {
        ...state,
        pagesCount: action.pagesCount,
      };
    case SET_REPORTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case SET_SELECTED_ACCOUNT_TYPE:
      return {
        ...state,
        selectedAccountType: action.selectedAccountType,
      };
    case SET_SELECTED_WORK_TYPE:
      return {
        ...state,
        selectedWorkType: action.selectedWorkType,
      };
    case SET_SELECTED_PROCESSING_STATUS:
      return {
        ...state,
        selectedProcessingStatus: action.selectedProcessingStatus,
      };
    default:
      return state;
  }
}