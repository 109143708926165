import React from 'react'
import ReactDOM from 'react-dom'
import useModal from '@/hooks/useModal'
import Modal from '@/components/Modals'
import style from './style.module.scss'

export default () => {
  const { settings } = useModal()
  const {
    isVisible,
    type,
  } = settings
  return (isVisible && ReactDOM.createPortal(
    <div className={style.modalWrapper}>
      <div className={style.modalBody} onClick={(e) => e.stopPropagation()}>
        <Modal type={type} />
      </div>
    </div>,
    document.body,
  )
  )
}