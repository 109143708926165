export default class Storage {
  static get = (field) => {
    return localStorage.getItem(field)
  }

  static set = (field, value) => {
    localStorage.setItem(field, value)
  }

  static remove = (field) => {
    localStorage.removeItem(field)
  }
}