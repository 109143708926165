import { SHOW_CAMP_MODAL, HIDE_CAMP_MODAL, SET_CAMP_DATA } from '@actions/actionTypes'

const initialState = {
  isShowModal: false,
  group: null,
  type: null,
  data: null,
}

export default function campModal(state = initialState, action) {
  switch (action.type) {
    case SHOW_CAMP_MODAL:
      return {
        ...state,
        isShowModal: true,
        group: action.group,
        type: action.modalType,
      }

    case SET_CAMP_DATA:
      return {
        ...state,
        data: action.data,
      }

    case HIDE_CAMP_MODAL:
      return {
        ...state,
        isShowModal: false,
        group: null,
        type: null,
        data: null,
      }
    default:
      return state
  }
}