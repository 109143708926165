import React from 'react'
import closeICO from '@images/SVG/x'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import style from './style_d.module.scss'

export default () => {
  const { changeSettings } = useModal()
  const { t } = useTranslation()
  const onCloseHandler = () => {
    changeSettings(false)
  }
  return (
    <div className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <span onClick={onCloseHandler}>
            <img src={closeICO} alt="close" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <h1>{t('modals:link_on_email')}</h1>
        <p>{t('modals:confirmation_email')}</p>
        <p>{t('modals:not_received')}</p>
        <h2>+38 095 583 82 83</h2>
        <NavLink to="/" onClick={onCloseHandler}>{t('buttons:on_main')}</NavLink>
      </main>
    </div>
  )
}