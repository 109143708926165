import React from 'react'
import { Route } from 'react-router-dom'
import LandingLayout from '../../Layout/Landing/LandingLayout'

export default function LandingRoute({
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => (
        <LandingLayout>
          <Component {...props} />
        </LandingLayout>
      )}
    />
  )
}