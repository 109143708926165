import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Dashboard from '@img/Dashbord'
import Courses from '@img/Courses'
import classes from './asideBar.module.scss'

export default function UserAsideBar() {
  const { t } = useTranslation(['titles'])

  return (
    <>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/profile" exact>
          <img src={Dashboard} alt="Dashboard"/>
          <span>{t('titles:main')}</span>
        </NavLink>
      </li>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/profile/courses">
          <img src={Courses} alt="Courses"/>
          <span>{t('titles:courses')}</span>
        </NavLink>
      </li>
    </>
  )
}