import {
  HIDE_ADM_USER_MODAL,
  SHOW_ADM_USER_MODAL,
  SET_MODAL_SELECTED_OPTIONS,
} from '@actions/actionTypes'

const initialState = {
  isShowModal: false,
  user: null,
  type: null,
  data: null,
  selectedOptions: {},
}

export default function AdmUserModal(state = initialState, action) {
  switch (action.type) {
    case HIDE_ADM_USER_MODAL:
      return {
        ...state,
        isShowModal: false,
        user: null,
        type: null,
        data: null,
        selectedOptions: {},
      }
    case SHOW_ADM_USER_MODAL:
      return {
        ...state,
        isShowModal: true,
        user: action.user,
        type: action.modalType,
        data: action.data,
      }
    case SET_MODAL_SELECTED_OPTIONS:
      return {
        ...state,
        selectedOptions: action.selectedOptions,
      }
    default:
      return state
  }
}