import React from 'react'
import AsideBar from '../AsideBar/AsideBar'
import HeaderComponent from '../Header/Header'
import classes from './classes.module.scss'

export default ({ children }) => {
  return (
    <>
      <HeaderComponent />
      <main className={classes.main}>
        <AsideBar />
        <article className={classes.main_content}>
          {children}
        </article>
      </main>
    </>
  )
}