import {
  REMOVE_LESSON_DATA,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  NEW_ANSWERS,
  LESSON_COURSE_ID,
  LESSON_CONTENT_ID,
} from '@actions/actionTypes'

const initialState = {
  exercises: [],
  loading: true,
  error: false,
  courseId: null,
  contentId: null,
}

export default function exerciseReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_LESSON_DATA:
      return {
        ...state,
        exercises: [],
        loading: true,
        error: false,
        courseId: null,
        contentId: null,
      }
    case FETCH_START:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        exercises: action.exercises,
        loading: false,
      }
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case NEW_ANSWERS:
      return {
        ...state,
        exercises: action.newAnswers,
      }
    case LESSON_COURSE_ID:
      return {
        ...state,
        courseId: action.courseId,
      }
    case LESSON_CONTENT_ID:
      return {
        ...state,
        contentId: action.contentId,
      }
    default:
      return state
  }
}