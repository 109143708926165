import {
  ADD_EXAM_ID,
  START_INTRODUCTORY_TEST,
  ADD_TEST_ANSWER,
  FINISH_INTRODUCTORY_TEST,
} from '@actions/actionTypes'

const initialState = {
  examId: null,
  questions: [],
  answers: {},
}

export default function IntroTest(state = initialState, action) {
  switch (action.type) {
    case ADD_EXAM_ID:
      return {
        ...state,
        examId: action.examId,
      }
    case START_INTRODUCTORY_TEST:
      return {
        ...state,
        questions: action.questions,
      }
    case ADD_TEST_ANSWER:
      return {
        ...state,
        answers: action.answers,
      }
    case FINISH_INTRODUCTORY_TEST:
      return {
        ...state,
        examId: null,
        questions: [],
        answers: {},
      }
    default:
      return state
  }
}