import React from 'react'
import useModal from '@/hooks/useModal'
import useAlert from '@/hooks/useAlert'
import useProfileData from '@/hooks/useProfileData'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { teacherSignIn, currentUserInfo } from '@services/fetchServices'
import Storage from '@services/storage'
import * as yup from 'yup'
import closeIcon from '@images/SVG/x'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import style from '../style_d.module.scss'

export default () => {
  const { changeSettings } = useModal()
  const { changeAlertSettings } = useAlert()
  const { changePerson } = useProfileData()
  const history = useHistory()
  const { t } = useTranslation()

  const onCloseHandler = () => {
    changeSettings(false)
  }

  const onClickHandler = e => {
    const { type } = e.target.dataset

    if (type === 'pass_recovery') {
      changeSettings(true, { type, previousType: 'log_in_teacher' })
    } else {
      changeSettings(true, { type })
    }
  }

  const submitLogIn = (values, actions) => {
    teacherSignIn(values)
      .finally(() => actions.setSubmitting(false))
      .then(() => {
        if (Storage.get('auth_token')) {
          currentUserInfo()
            .then(res => {
              changePerson(res)
              changeAlertSettings(true, {
                type: 'success',
                message: 'Вхід успішний!',
              })
            })
            .then(() => history.push('/teacher_profile'))
            .then(() => changeSettings(false))
        }
      })
  }

  const schema = yup.object({
    email: yup
      .string()
      .required('Будь ласка, введіть email')
      .email('Невірна електронна пошта'),
    password: yup
      .string()
      .required('Будь ласка, введіть пароль'),
  })

  return (
    <section className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <span onClick={onCloseHandler}>
            <img src={closeIcon} alt="close" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <div className={style.title}>
          <h1>{t('modals:log_in_as_teacher')}</h1>
          <button
            type="button"
            onClick={onClickHandler}
            data-type="log_in_user"
          >
            {t('buttons:log_in')}
          </button>
        </div>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={schema}
          onSubmit={submitLogIn}
        >
          {formik => (
            <Form className={style.inputGroup}>
              <div className={style.inputWrapper}>
                <Field
                  className={style.input}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  className={style.warning}
                  component="span"
                  name="email"
                />
              </div>
              <div className={style.inputWrapper}>
                <Field
                  className={style.input}
                  name="password"
                  type="password"
                  placeholder="Пароль"
                />
                <ErrorMessage
                  className={style.warning}
                  component="span"
                  name="password"
                />
              </div>
              <button
                type="button"
                onClick={onClickHandler}
                data-type="pass_recovery"
              >
                {t('modals:forget_password')}
              </button>
              <button
                type="submit"
                disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                {t('buttons:log_in')}
              </button>
            </Form>
          )}
        </Formik>
      </main>
    </section>
  )
}