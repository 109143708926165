import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Chevron from '@img/chevron'
import styles from './styles.module.scss'

export default () => {
  const [adultsVisibility, setAdultsVisibility] = useState(null)
  const [languagesVisibility, setLanguagesVisibility] = useState(null)
  const { t } = useTranslation()

  const changeAdultsVisibility = () => {
    if (!adultsVisibility) {
      setAdultsVisibility({
        display: 'block',
      })
    } else {
      setAdultsVisibility(null)
    }
  }

  const changeLanguagesVisibility = () => {
    if (!languagesVisibility) {
      setLanguagesVisibility({
        display: 'block',
      })
    } else {
      setLanguagesVisibility(null)
    }
  }

  const adultsArrowClasses = [styles.chevron]
  const languagesArrowClasses = [styles.chevron]

  if (adultsVisibility) adultsArrowClasses.push(styles.open)
  if (languagesVisibility) languagesArrowClasses.push(styles.open)

  return (
    <nav>
      <ul>
        <li><Link to="/">{t('navigation:main')}</Link></li>
        <li>
          <div className={styles.dropdownMenu}>
            <button
              type="button"
              onClick={changeAdultsVisibility}
            >
              <span>{t('navigation:adults')}</span>
              <img
                className={adultsArrowClasses.join(' ')}
                src={Chevron}
                alt="Chevron"
              />
            </button>
            <ul className={styles.list} style={adultsVisibility}>
              <li className={styles.item}><Link to="/personal">{t('navigation:personal')}</Link></li>
              <li className={styles.item}><Link to="/group">{t('navigation:group')}</Link></li>
              <li className={styles.item}><Link to="/combined_course">{t('navigation:combined_course')}</Link></li>
            </ul>
          </div>
        </li>
        <li><Link to="/english_for_teens">{t('navigation:children_and_teenagers')}</Link></li>
        <li>
          <div className={styles.dropdownMenu}>
            <button
              type="button"
              onClick={changeLanguagesVisibility}
            >
              <span>{t('navigation:languages')}</span>
              <img
                className={languagesArrowClasses.join(' ')}
                src={Chevron}
                alt="Chevron"
              />
            </button>
            <ul className={styles.list} style={languagesVisibility}>
              <li className={styles.item}><Link to="/spanish">{t('navigation:spanish')}</Link></li>
              <li className={styles.item}><Link to="/polish">{t('navigation:polish')}</Link></li>
              <li className={styles.item}><Link to="/french">{t('navigation:french')}</Link></li>
              <li className={styles.item}><Link to="/italian">{t('navigation:italian')}</Link></li>
              <li className={styles.item}><Link to="/german">{t('navigation:german')}</Link></li>
            </ul>
          </div>
        </li>
        <li><Link to="/teachers">{t('navigation:teachers')}</Link></li>
        <li><Link to="/about">{t('navigation:school')}</Link></li>
        <li><Link to="/articles">{t('navigation:blog')}</Link></li>
      </ul>
    </nav>
  )
}