export const REMOVE_LESSON_DATA = 'REMOVE_LESSON_DATA'
export const FETCH_START = 'FETCH_EXERCISES_START'
export const FETCH_SUCCESS = 'FETCH_EXERCISES_SUCCESS'
export const FETCH_ERROR = 'FETCH_EXERCISES_ERROR'
export const NEW_ANSWERS = 'NEW_ANSWERS'
export const LESSON_COURSE_ID = 'LESSON_COURSE_ID'
export const LESSON_CONTENT_ID = 'LESSON_CONTENT_ID'

export const SET_ACCOUNT_ROLE = 'SET_ACCOUNT_ROLE'
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT'

export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const SET_USER_TEST = 'SET_USER_TEST'

export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS'
export const FETCH_STUDENTS_ERROR = 'FETCH_STUDENTS_ERROR'
export const FETCH_STUDENTS_START = 'FETCH_STUDENTS_START'

export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_ALERT = 'SHOW_ALERT'
export const CHANGE_LANG = 'CHANGE_LANG'
export const SHOW_PROFILE = 'SHOW_PROFILE'
export const HIDE_PROFILE = 'HIDE_PROFILE'

export const SHOW_CHARACTERISTIC_DATA = 'SHOW_CHARACTERISTIC_DATA'
export const SHOW_PERSONAL_DATA = 'SHOW_PERSONAL_DATA'

export const SHOW_ZOOM_MODAL = 'SHOW_ZOOM_MODAL'
export const HIDE_ZOOM_MODAL = 'HIDE_ZOOM_MODAL'

export const ADD_EXAM_ID = 'ADD_EXAM_ID'
export const START_INTRODUCTORY_TEST = 'START_INTRODUCTORY_TEST'
export const ADD_TEST_ANSWER = 'ADD_TEST_ANSWER'
export const FINISH_INTRODUCTORY_TEST = 'FINISH_INTRODUCTORY_TEST'

export const SHOW_MATERALS_MODAL = 'SHOW_MATERALS_MODAL'
export const HIDE_MATERALS_MODAL = 'HIDE_MATERALS_MODAL'
export const SET_MATERALS_DATA = 'SET_MATERALS_DATA'

export const SHOW_REPORT_MODAL = 'SHOW_REPORT_MODAL'
export const SET_BASIC_REPORT_DATA = 'SET_BASIC_REPORT_DATA'
export const SET_SELECTED_REPORT_RESON = 'SET_SELECTED_REPORT_RESON'
export const SET_REPORT_COMMENT = 'SET_REPORT_COMMENT'
export const HIDE_REPORT_MODAL = 'HIDE_REPORT_MODAL'

export const SHOW_FINISH_LESSON_MODAL = 'SHOW_FINISH_LESSON_MODAL'
export const HIDE_FINISH_LESSON_MODAL = 'HIDE_FINISH_LESSON_MODAL'
export const SET_DATA_TO_MODAL = 'SET_DATA_TO_MODAL'

export const SHOW_ASIDE_BAR = 'SHOW_ASIDE_BAR'
export const HIDE_ASIDE_BAR = 'HIDE_ASIDE_BAR'

export const SHOW_APPROVAL_MODAL = 'SHOW_APPROVAL_MODAL'
export const HIDE_APPROVAL_MODAL = 'HIDE_APPROVAL_MODAL'

export const SHOW_ARTICLES_MODAL = 'SHOW_ARTICLES_MODAL'
export const HIDE_ARTICLES_MODAL = 'HIDE_ARTICLES_MODAL'

export const SHOW_CAMP_MODAL = 'SHOW_CAMP_MODAL'
export const SET_CAMP_DATA = 'SET_CAMP_DATA'
export const HIDE_CAMP_MODAL = 'HIDE_CAMP_MODAL'

export const UPDATE_USERS_DATA = 'UPDATE_USERS_DATA'
export const SET_TASKS = 'SET_TASKS'
export const CHANGE_USER_LIST = 'CHANGE_USER_LIST'
export const SET_USERS_PAGES_COUNT = 'SET_USERS_PAGES_COUNT'
export const SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE'
export const UPDATE_GLOBAL_LOADING_STATUS = 'UPDATE_GLOBAL_LOADING_STATUS'
export const CHANGE_USER_SEARCHWORD = 'CHANGE_USER_SEARCHWORD'
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS'
export const UPDATE_LAST_REQUEST = 'UPDATE_LAST_REQUEST'

export const SHOW_DATE_PICKER_MODAL = 'SHOW_DATE_PICKER_MODAL'
export const HIDE_DATE_PICKER_MODAL = 'HIDE_DATE_PICKER_MODAL'
export const SET_DATE = 'SET_DATE'

export const GET_COURSE_UNITS = 'GET_COURSE_UNITS'
export const GET_COURSE_CONTENTS_UNITS = 'GET_COURSE_CONTENTS_UNITS'
export const LOAD_UNIT_EXERCISES = 'LOAD_UNIT_EXERCISES'
export const UPDATE_LESSON_EXERCISES = 'UPDATE_LESSON_EXERCISES'
export const UPDATE_LESSON_HOMEWORKS = 'UPDATE_LESSON_HOMEWORKS'
export const ADD_NEW_HOMEWORK = 'ADD_NEW_HOMEWORK'
export const REMOVE_CONSTRUCTOR_DATA = 'DELETE_EXERCISE_FROM_LESSON'
export const SET_CHECKED_UNIT = 'SET_CHECKED_UNIT'
export const SET_CHECKED_HOMEWORK = 'SET_CHECKED_HOMEWORK'
export const SET_ALL_CHECKED = 'SET_ALL_CHECKED'

export const RELOAD_CARD_LIST = 'RELOAD_CARD_LIST'

export const HIDE_ADM_USER_MODAL = 'HIDE_ADM_USER_MODAL'
export const SHOW_ADM_USER_MODAL = 'SHOW_ADM_USER_MODAL'
export const SET_MODAL_SELECTED_OPTIONS = 'SET_MODAL_SELECTED_OPTIONS'

export const SET_ADM_USER_PROFILE_DATA = 'SET_ADM_USER_PROFILE_DATA'

export const SET_STUDENTS_CAMPS = 'SET_STUDENTS_CAMPS'
export const SET_SEARCH_STUDENTS_CAMPS_VALUE = 'SET_SEARCH_STUDENTS_CAMPS_VALUE'
export const SET_SELECTED_STUDING_STATUS = 'SET_SELECTED_STUDING_STATUS'
export const SET_CAMP_STUDENTS = 'SET_CAMP_STUDENTS'
export const SET_SEARCH_CAMP_STUDENTS_VALUE = 'SET_SEARCH_CAMP_STUDENTS_VALUE'

export const FETCH_TEACHERS_DATA = 'FETCH_TEACHERS_DATA'
export const FETCH_COMMENTS_DATA = 'FETCH_COMMENTS_DATA'
export const SET_COMMENTS_PAGES_COUNT = 'SET_COMMENTS_PAGES_COUNT'
export const SET_COMMENTS_CURRENT_PAGE = 'SET_COMMENTS_CURRENT_PAGE'
export const SET_SEARCH_USER_ID = 'SET_SEARCH_USER_ID'
export const SET_SELECTED_TEACHER_ID = 'SET_SELECTED_TEACHER_ID'
export const SET_SELECTED_USER_MARK = 'SET_SELECTED_USER_MARK'

export const FETCH_REPORTS_DATA = 'FETCH_REPORTS_DATA'
export const SET_REPORTS_PAGES_COUNT = 'SET_REPORTS_PAGES_COUNT'
export const SET_REPORTS_CURRENT_PAGE = 'SET_REPORTS_CURRENT_PAGE'
export const SET_SELECTED_ACCOUNT_TYPE = 'SET_SELECTED_ACCOUNT_TYPE'
export const SET_SELECTED_WORK_TYPE = 'SET_SELECTED_WORK_TYPE'
export const SET_SELECTED_PROCESSING_STATUS = 'SET_SELECTED_PROCESSING_STATUS'

export const SET_AVAILABLE_BOUGHT_PACKAGES = 'SET_AVAILABLE_BOUGHT_PACKAGES'

export const SET_ALL_BOUGHT_PACKAGES_HISTORY = 'SET_ALL_BOUGHT_PACKAGES_HISTORY'
export const FETCH_BOUGHT_PACKAGES_HISTORY_DATA = 'FETCH_BOUGHT_PACKAGES_HISTORY_DATA'
export const SET_BOUGHT_PACKAGES_HISTORY_PAGES_COUNT = 'SET_BOUGHT_PACKAGES_HISTORY_PAGES_COUNT'
export const SET_BOUGHT_PACKAGES_HISTORY_CURRENT_PAGE = 'SET_BOUGHT_PACKAGES_HISTORY_CURRENT_PAGE'