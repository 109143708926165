import { SHOW_APPROVAL_MODAL, HIDE_APPROVAL_MODAL } from '@actions/actionTypes'

const initialState = {
  isShowModal: false,
  callback: null,
  type: null,
}

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_APPROVAL_MODAL:
      return {
        ...state,
        isShowModal: true,
        callback: action.callback,
        type: action.actionType,
      }
    case HIDE_APPROVAL_MODAL:
      return {
        ...state,
        isShowModal: false,
        callback: null,
        type: null,
      }
    default:
      return state
  }
}