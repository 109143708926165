import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { signOut } from '@services/fetchServices'
import Storage from '@services/storage'
import classes from './Modal.module.scss'

export default function Profile({
  role,
  onAction,
}) {
  const history = useHistory()
  const { t } = useTranslation()

  const moveHelper = path => {
    history.push(path)
  }

  const logout = () => {
    signOut()
      .then(() => {
        Storage.remove('auth_token')
        window.location.href = '/'
      })
  }

  return (
    <>
      <div
        className={`${classes.link_btn} ${classes.link_border}`}
        onClick={() => {
          if (role === 'teacher') {
            moveHelper('/teacher_profile/edit')
          } else {
            moveHelper('/profile/edit')
          }

          onAction(false)
        }}
      >
        {t('buttons:settings')}
      </div>
      {
        role === 'user'
          ? <div
              className={`${classes.link_btn} ${classes.link_border}`}
              onClick={() => {
                moveHelper('/profile/payment_history')
                onAction(false)
              }}
          >
            {t('buttons:payment_history')}
          </div>
          : null
      }
      <div onClick={logout} className={classes.link_btn}>
        {t('buttons:exit')}
      </div>
    </>
  )
}