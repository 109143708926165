import React from 'react'
import logo from '@images/SVG/logo'
import closeICO from '@/assets/images/SVG/x'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import style from './style_m.module.scss'

const SendInfo = () => {
  const { changeSettings } = useModal()
  const onCloseHandler = () => {
    changeSettings(false)
  }
  const { t } = useTranslation()
  return (
    <div className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <img src={logo} alt="" />
          <span onClick={onCloseHandler}>
            <img src={closeICO} alt="" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <h1>{t('modals:link_on_email')}</h1>
        <p>{t('modals:confirmation_account')}</p>
        <p>{t('modals:not_received')}</p>
        <h2>+38 095 583 82 83</h2>
        <button onClick={onCloseHandler} type="button">На головну</button>
      </main>
    </div>
  )
}

export default SendInfo