import { SHOW_ZOOM_MODAL, HIDE_ZOOM_MODAL } from '@actions/actionTypes'

const initialState = {
  isShowZoom: false,
  liveLessonId: null,
}

export default function zoomReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ZOOM_MODAL:
      return {
        ...state,
        isShowZoom: true,
        liveLessonId: action.liveLessonId,
      }
    case HIDE_ZOOM_MODAL:
      return {
        ...state,
        isShowZoom: false,
        liveLessonId: null,
      }
    default:
      return state
  }
}