import { SHOW_MATERALS_MODAL, HIDE_MATERALS_MODAL, SET_MATERALS_DATA } from '@actions/actionTypes'

const initialState = {
  materails: null,
  materialsType: null,
  lessonId: null,
  isShow: false,
}

export default function materialsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MATERALS_DATA:
      return {
        ...state,
        materails: action.materials,
        materialsType: action.materialsType,
        lessonId: action.lessonId,
      }
    case SHOW_MATERALS_MODAL:
      return {
        ...state,
        isShow: true,
      }
    case HIDE_MATERALS_MODAL:
      return {
        ...state,
        materails: null,
        isShow: false,
        materialsType: null,
        lessonId: null,
      }
    default:
      return state
  }
}