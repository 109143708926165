import {
  FETCH_TEACHERS_DATA,
  FETCH_COMMENTS_DATA,
  SET_COMMENTS_PAGES_COUNT,
  SET_COMMENTS_CURRENT_PAGE,
  SET_SEARCH_USER_ID,
  SET_SELECTED_TEACHER_ID,
  SET_SELECTED_USER_MARK,
} from '@actions/actionTypes';

const initialState = {
  teacherList: [],
  commentList: {},
  pagesCount: null,
  currentPage: null,
  searchUserId: '',
  selectedTeacherId: null,
  selectedUserMark: null,
};

export default function userCommentsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEACHERS_DATA:
      return {
        ...state,
        teacherList: action.teacherList,
      };
    case FETCH_COMMENTS_DATA:
      return {
        ...state,
        commentList: action.commentList,
      };
    case SET_COMMENTS_PAGES_COUNT:
      return {
        ...state,
        pagesCount: action.pagesCount,
      };
    case SET_COMMENTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case SET_SEARCH_USER_ID:
      return {
        ...state,
        searchUserId: action.searchUserId,
      };
    case SET_SELECTED_TEACHER_ID:
      return {
        ...state,
        selectedTeacherId: action.selectedTeacherId,
      };
    case SET_SELECTED_USER_MARK:
      return {
        ...state,
        selectedUserMark: action.selectedUserMark,
      };
    default:
      return state;
  }
}