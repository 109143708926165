import { RELOAD_CARD_LIST } from '@actions/actionTypes'

const initialState = {
  isReload: false,
}

export default function cardListReducer(state = initialState, action) {
  switch (action.type) {
    case RELOAD_CARD_LIST:
      return {
        ...state,
        isReload: action.isReload,
      }
    default:
      return state
  }
}