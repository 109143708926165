import {
  SHOW_PROFILE,
  SET_USER_ROLE,
  SET_USER_TEST,
  SET_USER_DATA,
  HIDE_PROFILE,
  SHOW_CHARACTERISTIC_DATA,
  SHOW_PERSONAL_DATA,
} from '@actions/actionTypes'

const initialState = {
  isUserProfileShow: false,
  isPersonalMain: true,
  personId: null,
  personRole: null,
  personTest: null,
  personInfo: {},
}

export default function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_PROFILE:
      return {
        ...state,
        isUserProfileShow: true,
        personId: action.id,
      }
    case SET_USER_DATA:
      return {
        ...state,
        personInfo: action.info,
      }
    case SET_USER_ROLE:
      return {
        ...state,
        personRole: action.role,
      }
    case SET_USER_TEST:
      return {
        ...state,
        personTest: action.test,
      }
    case HIDE_PROFILE:
      return {
        ...state,
        isUserProfileShow: false,
        personId: null,
        isPersonalMain: true,
        personRole: null,
        personTest: null,
        personInfo: {},
      }
    case SHOW_PERSONAL_DATA:
      return {
        ...state,
        isPersonalMain: true,
      }
    case SHOW_CHARACTERISTIC_DATA:
      return {
        ...state,
        isPersonalMain: false,
      }
    default:
      return state
  }
}