import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import logo from './img/logo'
import menu from './img/menu'
import Aside from './Aside'

export default function Header() {
  const [isShowAsideBar, setShowAsideBar] = useState(false)
  const location = useLocation()

  const styles = {
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 35px',
      height: '70px',
    },
    menu: {
      cursor: 'pointer',
    },
  }

  const menuToggle = () => setShowAsideBar(state => !state)

  useEffect(() => {
    setShowAsideBar(false)
  }, [location])

  return (
    <header style={styles.header}>
      <Link to="/"><img src={logo} alt="logo" width="68" height="36" style={{ display: 'block' }} /></Link>
      <img src={menu} alt="menu" style={styles.menu} onClick={menuToggle} width="20" height="14" />
      {
        isShowAsideBar && <Aside onClose={menuToggle} />
      }
    </header>
  )
}