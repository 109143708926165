import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { hideApprovalModal } from '@actions/ApprovalActions'
import Button from '../../Buttons/Button'
import classes from './ApprovalModal.module.scss'

export default function ApprovalModal() {
  const dispatch = useDispatch()
  const ApprovalModalData = useSelector(state => state.approvalModal)
  const { t } = useTranslation(['buttons', 'general'])

  const msg = {
    change_room: t('general:approval.change_room'),
    finish_lesson: t('general:approval.finish_lesson'),
    failed_lesson: t('general:approval.failed_lesson'),
  }

  function cancelApproval() {
    dispatch(hideApprovalModal())
  }

  function successedApproval() {
    ApprovalModalData.callback()
    cancelApproval()
  }

  return <section className={classes.ApprovalBlock}>
    <section className={classes.ApprovalModal}>
      <h1>{msg[`${ApprovalModalData.type}`] || t('general:approval.general')}</h1>
      <div className={classes.btn_menu}>
        <Button
          onClick={successedApproval}
          color="teal"
          width="w100"
        >
          {t('buttons:confirm')}
        </Button>
        <Button
          onClick={cancelApproval}
          color="coral"
          width="w100"
        >
          {t('buttons:cancel')}
        </Button>
      </div>
    </section>
  </section>
}