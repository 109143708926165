import React from 'react'
import useModal from '@/hooks/useModal'
import { useTranslation } from 'react-i18next'
import { resetUserPassword } from '@services/fetchServices'
import * as yup from 'yup'
import logo from '@images/SVG/logo'
import closeIcon from '@images/SVG/x'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import style from './style_m.module.scss'

export default () => {
  const {
    settings,
    changeSettings,
  } = useModal()
  const { t } = useTranslation()

  const onCloseHandler = () => {
    changeSettings(false)
  }

  const onClickHandler = e => {
    const { type } = e.target.dataset

    changeSettings(true, { type })
  }

  const submitHandler = (values, actions) => {
    resetUserPassword(values)
      .finally(() => actions.setSubmitting(false))
      .then(res => {
        if (res.status === 200) {
          changeSettings(true, { type: 'send_info' })
        }
      })
  }

  const schema = yup.object({
    email: yup
      .string()
      .required('Будь ласка, введіть email')
      .email('Невірна електронна пошта'),
  })

  return (
    <div className={style.modalContainer}>
      <header className={style.modalHead}>
        <div className={style.logoContainer}>
          <img src={logo} alt="logo" />
          <span onClick={onCloseHandler}>
            <img src={closeIcon} alt="close" />
          </span>
        </div>
      </header>
      <main className={style.modalBody}>
        <div className={style.title}>
          <h1>{t('modals:forget_password')}</h1>
          <button
            type="button"
            onClick={onClickHandler}
            data-type={
              settings.previousType === 'log_in_user'
                ? 'log_in_user'
                : 'log_in_teacher'
            }
          >
            {
              settings.previousType === 'log_in_user'
                ? t('modals:log_in')
                : t('modals:log_in_as_teacher')
            }
          </button>
        </div>
        <p>{t('modals:enter_email')}</p>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {formik => (
            <Form className={style.inputGroup}>
              <div className={style.inputWrapper}>
                <Field
                  className={style.input}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  className={style.warning}
                  component="span"
                  name="email"
                />
              </div>
              <button
                className={style.input}
                type="submit"
                disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                {t('buttons:send')}
              </button>
            </Form>
          )}
        </Formik>
      </main>
    </div>
  )
}