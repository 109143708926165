import { SHOW_ASIDE_BAR, HIDE_ASIDE_BAR } from '@actions/actionTypes'

const initialState = {
  isShow: false,
}

export default function asideBarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ASIDE_BAR:
      return {
        ...state,
        isShow: true,
      }
    case HIDE_ASIDE_BAR:
      return {
        ...state,
        isShow: false,
      }
    default:
      return state
  }
}