import React from 'react'
import Registration from '@/components/Modals/LandingModals/Registration'
import ConfirmAccount from '@/components/Modals/LandingModals/ConfirmAccount'
import NewPass from '@/components/Modals/LandingModals/NewPass'
import PassRecovery from '@/components/Modals/LandingModals/PassRecovery'
import SendInfo from '@/components/Modals/LandingModals/SendInfo'
import LogInAsUser from '@/components/Modals/LandingModals/LogIn/LogInAsUser'
import LogInAsTeacher from '@/components/Modals/LandingModals/LogIn/LogInAsTeacher'
import PrivacyPolicy from '@/components/Modals/LandingModals/PrivacyPolicy'
import PublicOffer from '@/components/Modals/LandingModals/PublicOffer'
import WebSiteConditions from '@/components/Modals/LandingModals/WebSiteConditions'
import Vacancies from '@/components/Modals/LandingModals/Vacancies'
import Marketing from '@/components/Modals/LandingModals/Marketing'

export default ({ type }) => {
  const modals = {
    registration: Registration,
    confirm_account: ConfirmAccount,
    new_pass: NewPass,
    pass_recovery: PassRecovery,
    send_info: SendInfo,
    log_in_user: LogInAsUser,
    log_in_teacher: LogInAsTeacher,
    privacy_policy: PrivacyPolicy,
    public_offer: PublicOffer,
    website_conditions: WebSiteConditions,
    vacancies: Vacancies,
    marketing: Marketing,
  }
  const Modal = modals[type]
  return (
    <Modal />
  )
}