import React, { useState } from 'react'
import Media from 'react-media'
import { useTranslation } from 'react-i18next'
import Mobile from './Chooser.mobile'
import Desktop from './Chooser.desktop'

const LANGUAGES = [
  {
    value: 'uk',
    label: 'UA',
  },
  // {
  //   value: 'ru',
  //   label: 'RU',
  // },
]

function initLangState(lang) {
  if (lang === 'ru') return lang
  return 'uk'
}

export default () => {
  const { i18n } = useTranslation()
  const [currentLang, setCurrentLang] = useState(initLangState(i18n.language))

  const chooseLangHandler = (value) => {
    i18n.changeLanguage(value)
      .then(() => setCurrentLang(value))
  }

  return (
    <Media
      queries={{
        small: '(max-width: 576px)',
        large: '(min-width: 577px)',
      }}
    >
      {
        matches => (
          <>
            {
              matches.small && <Mobile
                lang={LANGUAGES}
                currentLang={currentLang}
                onChange={chooseLangHandler}
              />
            }
            {
              matches.large && <Desktop
                lang={LANGUAGES}
                currentLang={currentLang}
                onChange={chooseLangHandler}
              />
            }
          </>
        )
      }
    </Media>
  )
}