import React, { useMemo } from 'react'
import { useField, ErrorMessage } from 'formik'
import classes from './style/classes.module.scss'

export default ({
  isEmailAlreadyExists,
  setIsEmailAlreadyExists,
  ...props
}) => {
  const [field] = useField(props)
  const htmlFor = useMemo(() => `${field.name}-${Math.random()}`, [])

  const changeHandler = e => {
    field.onChange(e)

    if (isEmailAlreadyExists) setIsEmailAlreadyExists(false)
  }

  return (
    <label htmlFor={htmlFor}>
      <div className={classes.inputWrapper}>
        <input
          id={htmlFor}
          {...field}
          {...props}
          onChange={changeHandler}
          autoComplete="off"
        />
        <ErrorMessage
          className={classes.warning}
          component="span"
          name={field.name}
        />
        {
          isEmailAlreadyExists
          && <span className={classes.warning}>Така електронна пошта вже існує!</span>
        }
      </div>
    </label>
  )
}