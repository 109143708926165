import React, { useMemo } from 'react'
import { useField, ErrorMessage } from 'formik'
import classes from './style/classes.module.scss'

export default ({
  children,
  ...props
}) => {
  const [field] = useField(props)
  const htmlFor = useMemo(() => `${field.name}-${Math.random()}`, [])

  return (
    <label htmlFor={htmlFor} className={classes.checkbox}>
      <div className={classes.checkboxWrapper}>
        <input
          id={htmlFor}
          {...field}
          {...props}
        />
        <span className={classes.check} />
        <p>{children}</p>
        <ErrorMessage
          className={classes.warning}
          component="span"
          name={field.name}
        />
      </div>
    </label>
  )
}