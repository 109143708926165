import { SHOW_DATE_PICKER_MODAL, HIDE_DATE_PICKER_MODAL, SET_DATE } from '@actions/actionTypes'

const initialState = {
  isShow: false,
  date: null,
  userId: null,
  operationId: null,
  previousStatus: null,
  newStatus: null,
  isUpdated: false,
}

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_DATE_PICKER_MODAL:
      return {
        ...state,
        isShow: true,
        userId: action.userId,
        previousStatus: action.previousStatus,
        newStatus: action.newStatus,
        operationId: action.operationId,
        isUpdated: action.isUpdated,
      }
    case SET_DATE:
      return {
        ...state,
        date: action.date,
      }
    case HIDE_DATE_PICKER_MODAL:
      return {
        ...state,
        isShow: false,
        date: null,
        userId: null,
        operationId: null,
        previousStatus: null,
        newStatus: null,
        isUpdated: false,
      }
    default:
      return state
  }
}