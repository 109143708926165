import {
  FETCH_STUDENTS_ERROR,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_START,
} from '@actions/actionTypes'

const initialState = {
  lessons: [],
  loading: false,
  error: false,
}

export default function teacherReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STUDENTS_START:
      return {
        ...state,
        loading: true,
      }
    case FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        lessons: action.lessons,
      }
    case FETCH_STUDENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}