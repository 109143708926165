import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Dashboard from '@img/Dashbord'
import Previous from '@img/Previos'
import Courses from '@img/Courses'
import Books from '@img/Books'
import classes from './asideBar.module.scss'

export default function TeacherAsideBar() {
  const { t } = useTranslation(['titles'])

  return (
    <>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/teacher_profile" exact>
          <img src={Dashboard} alt="Dashboard" />
          <span>{t('titles:main')}</span>
        </NavLink>
      </li>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/teacher_profile/previous" exact>
          <img src={Previous} alt="Previous" />
          <span>{t('titles:previous')}</span>
        </NavLink>
      </li>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/teacher_profile/courses">
          <img src={Courses} alt="Courses" />
          <span>{t('titles:courses')}</span>
        </NavLink>
      </li>
      <li className={classes.AsideMenuElement}>
        <NavLink activeClassName={classes.Active} to="/teacher_profile/materials">
          <img src={Books} alt="Books" />
          <span>{t('titles:materials')}</span>
        </NavLink>
      </li>
    </>
  )
}