import React from 'react'
import ReactDOM from 'react-dom'
import useAlert from '@/hooks/useAlert'
import Alert from '@libs/Alert/Alert'

export default () => {
  const { alertSettings, changeAlertSettings } = useAlert()
  const { isVisible, type, message } = alertSettings
  setTimeout(() => {
    changeAlertSettings(false)
  }, 5000)
  return (isVisible && ReactDOM.createPortal(
    <Alert type={type} message={message} />,
    document.body,
  )
  )
}