import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Media from 'react-media'
import { PersonContext } from '@/contexts'
import Profile from '@libs/Modals/DropDownHeaderMenu/Profile'
import UserLinks from './UserAsideBar'
import TeacherLinks from './TeacherAsideBar'
import classes from './asideBar.module.scss'

export default function AsideBar() {
  const { i18n } = useTranslation()
  const { person } = useContext(PersonContext)
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const asideBar = useSelector(state => state.asideBar)
  const asideBarCls = [classes.AsideBar, classes.AsideBar_mobile]
  if (asideBar.isShow) asideBarCls.push(classes.AsideBar_mobile_active)

  const Links = person?.user.role === 'user' ? UserLinks : TeacherLinks

  const ua = [classes.lang]
  // const ru = [classes.lang]

  switch (currentLang) {
    case 'uk':
      ua.push(classes.active_lang)
      break;
    // case 'ru':
    //   ru.push(classes.active_lang)
    //   break;
    default:
      ua.push(classes.active_lang)
  }

  const setDefaultLang = lang => {
    i18n.changeLanguage(lang)
    setCurrentLang(lang)
  }

  function userInfo() {
    return (
      <div className={classes.userData}>
        {person && <img src={person.user.avatar_url} alt="avatar" className={classes.user_avatar} />}
        {person && <div className={classes.user_name}>
          <span>{person.user.name}</span>
          &nbsp;
          <span>{person.user.surname}</span>
        </div>}
      </div>
    )
  }

  return (
    <aside className={asideBarCls.join(' ')}>
      <Media
        query="(max-width: 576px)"
        render={() => userInfo()}
      />
      <ul>
        <Links />
      </ul>
      <Media
        query="(max-width: 576px)"
        render={() => (
          <div className={classes.profile_helper_menu}>
            {person?.role && <Profile role={person.user.role} />}
          </div>
        )}
      />
      <div className={classes.lang_panel}>
        <span onClick={() => setDefaultLang('uk')} className={ua.join(' ')}>ua</span>
        {/* |
        <span onClick={() => setDefaultLang('ru')} className={ru.join(' ')}>ru</span> */}
      </div>
    </aside>
  )
}