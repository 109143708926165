import { SHOW_ALERT, HIDE_ALERT } from '@actions/actionTypes'

const initialState = {
  isShowAlert: false,
  message: null,
  type: null,
}

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        isShowAlert: true,
        message: action.data.message,
        type: action.data.type,
      }
    case HIDE_ALERT:
      return {
        ...state,
        isShowAlert: false,
        message: null,
        type: null,
      }
    default:
      return state
  }
}