import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Storage from '@services/storage'
import ApprovalModal from '@libs/Modals/ApprovalModal/ApprovalModal'
import PersonLayout from '../../Layout/Person'

export default function PrivateRoute({
  personRole,
  component: Component,
  ...rest
}) {
  const isShowModal = useSelector(state => state.approvalModal.isShowModal)
  const token = Storage.get('auth_token')

  if (!token) {
    return <Redirect to={`/${personRole}/log_in`} />
  }

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <PersonLayout>
            <Component {...props} />
            {
              isShowModal && <ApprovalModal />
            }
          </PersonLayout>
        )
      }}
    />
  )
}