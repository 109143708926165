import React from 'react'
import Success from '@img/check-circle-regular'
import Error from '@img/exclamation-circle-solid'
import classes from './Alert.module.scss'

const Alert = ({
  message,
  type,
}) => {
  let logo
  const alert = [classes.alert]
  switch (type) {
    case 'success':
      logo = Success
      alert.push(classes.success)
      break;
    case 'error':
      logo = Error
      alert.push(classes.error)
      break;
    default:
      break
  }

  return <section className={alert.join(' ')}>
    <img src={logo} alt="alert" className={classes.alert_img} />
    {message
      ? <p className={classes.alert_message}>{message}</p>
      : <p className={classes.alert_title}>{type}</p>}
  </section>
}

export default Alert