import { SHOW_ASIDE_BAR, HIDE_ASIDE_BAR } from './actionTypes'

function showAsideBar() {
  return {
    type: SHOW_ASIDE_BAR,
  }
}

export function hideAsideBar() {
  return {
    type: HIDE_ASIDE_BAR,
  }
}

export function toggleAsideBar() {
  return (dispatch, getState) => {
    const { isShow } = getState().asideBar
    if (isShow) {
      dispatch(hideAsideBar())
    } else {
      dispatch(showAsideBar())
    }
  }
}