import React, { useState } from 'react'
// import chevron from '@images/SVG/chevron_down'
import classes from './classes.module.scss'

export default ({
  lang,
  currentLang,
  onChange,
}) => {
  const [showModal, setShow] = useState(false)

  const langHelper = (e) => {
    const { value } = e.target.dataset
    onChange(value)
    setShow(false)
  }

  const valueHelper = () => lang.find(el => el.value === currentLang).label

  // const modalToggle = () => setShow(state => !state)

  const optionsHelper = () => {
    const options = lang.filter(el => el.value !== currentLang)
    return options.map(el => {
      return (
        <li key={el.value} data-value={el.value} onClick={langHelper}>{el.label}</li>
      )
    })
  }

  return (
    <div className={classes.lang}>
      {/* <span onClick={modalToggle}></span> */}
      <span>
        {valueHelper()}
        {/* <img src={chevron} alt="chevron" width="10" height="5.7" /> */}
      </span>
      {
        showModal && <ul>{optionsHelper()}</ul>
      }
    </div>
  )
}