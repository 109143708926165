import {
  SHOW_REPORT_MODAL,
  SET_BASIC_REPORT_DATA,
  SET_SELECTED_REPORT_RESON,
  SET_REPORT_COMMENT,
  HIDE_REPORT_MODAL,
} from '@actions/actionTypes';

const initialState = {
  isShowReportModal: false,
  basicInfo: {},
  selectedReason: null,
  comment: '',
};

export default function reportModalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_REPORT_MODAL:
      return {
        ...state,
        isShowReportModal: true,
      };
    case SET_BASIC_REPORT_DATA:
      return {
        ...state,
        basicInfo: action.basicInfo,
      };
    case SET_SELECTED_REPORT_RESON:
      return {
        ...state,
        selectedReason: action.selectedReason,
      };
    case SET_REPORT_COMMENT:
      return {
        ...state,
        comment: action.comment,
      };
    case HIDE_REPORT_MODAL:
      return {
        ...state,
        isShowReportModal: false,
        basicInfo: {},
        selectedReason: null,
        comment: '',
      };
    default:
      return state;
  }
}