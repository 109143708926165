import React from 'react'
import { Link } from 'react-router-dom'

import { signOut } from '@services/fetchServices'
import Storage from '@services/storage'

const logout = () => {
  signOut()
    .then(() => {
      Storage.remove('auth_token')
      window.location.href = '/'
    })
}
const USER_LINKS = [
  {
    path: '/profile',
    title: 'Мій кабінет',
  },
  // { path: '/pay', title: 'Оплата' },
  // { path: '/invite', title: 'Запросити друга' },
]
const TEACHER_LINKS = [
  {
    path: '/teacher_profile',
    title: 'Мій кабінет',
  },
]

export default ({ role }) => {
  if (role === 'admin') return null

  let LINKS = TEACHER_LINKS
  if (role === 'user') LINKS = USER_LINKS

  return (
    <ul style={{ borderBottom: '1px solid', paddingTop: '15px' }}>
      {
        LINKS.map(l => <li key={l.path}><Link to={l.path}>{l.title}</Link></li>)
      }
      <li><Link to="/" onClick={logout}>Вийти</Link></li>
    </ul>
  )
}