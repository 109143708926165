import React, { useState, useMemo } from 'react'
import { useField, ErrorMessage } from 'formik'
import PhoneInput from 'react-phone-input-2'
import classes from './style/classes.module.scss'
import 'react-phone-input-2/lib/style.css'

export default ({
  setPhoneParameters,
  ...props
}) => {
  const [currentDialCode, setCurrentDialCode] = useState('380')
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380')
  const [field, , helpers] = useField(props)
  const htmlFor = useMemo(() => `${field.name}-${Math.random()}`, [])

  const phoneLengthEstimation = (...args) => {
    const minPhoneLengthLimit = args[1].dialCode.length
    const requiredPhoneLength = args[1].format.match(/[.]/g).length

    setPhoneParameters(minPhoneLengthLimit, requiredPhoneLength)
  }

  const phoneChangeHandler = (...args) => {
    if (args[3].length <= args[1].format.length) {
      helpers.setValue(`+${args[0]}`);
      setFormattedPhoneNumber(args[3])
    }

    if (args[1].dialCode !== currentDialCode) {
      helpers.setValue(`+${args[1].dialCode}`);
      setCurrentDialCode(args[1].dialCode)
      setFormattedPhoneNumber(`+${args[1].dialCode}`)
    }
  }

  const phoneBlurHandler = (...args) => {
    field.onBlur(args[0])
  }

  return (
    <label htmlFor={htmlFor}>
      <div className={classes.inputWrapper}>
        <PhoneInput
          country="ua"
          preferredCountries={['ua', 'pl', 'de', 'cz', 'it', 'es', 'gb', 'bg', 'fr', 'ro', 'sk']}
          excludeCountries={['ir', 'kp', 'ru']}
          inputProps={{ id: htmlFor, value: formattedPhoneNumber, autoComplete: 'off', ...props }}
          enableLongNumbers
          countryCodeEditable={false}
          containerClass={classes.phone}
          buttonClass={classes.flag}
          dropdownClass={classes.dd_list}
          onFocus={phoneLengthEstimation}
          onChange={phoneChangeHandler}
          onBlur={phoneBlurHandler}
        />
        <ErrorMessage
          className={classes.warning}
          component="span"
          name={field.name}
        />
      </div>
    </label>
  )
}