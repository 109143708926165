import React, { createContext, useState, useEffect } from 'react'
import Storage from '@services/storage'
import { currentUserInfo } from '@services/fetchServices'

export const PersonContext = createContext(null)

export const PersonProvider = ({ children }) => {
  const [person, setPerson] = useState()
  const changePerson = (data = null) => {
    setPerson({ ...data })
  }
  const token = Storage.get('auth_token')

  useEffect(() => {
    if (token) {
      currentUserInfo()
        .then(r => setPerson(r))
    }
  }, [])

  return (
    <PersonContext.Provider value={{
      person,
      changePerson,
    }}
    >
      {children}
    </PersonContext.Provider>
  )
}